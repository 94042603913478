import React, { useEffect, useState } from "react";
import styled from "styled-components";
import PrimaryButton from "../../../helperComponents/PrimaryButton";
import { AiOutlineClockCircle } from "react-icons/ai";
import { FaPersonMilitaryPointing } from "react-icons/fa6";
import { CgLock } from "react-icons/cg";
import { GoClockFill } from "react-icons/go";
import getImageURL from "../../../../controllers/getImageURL";
import { serverLine } from "../../../../controllers/serverLine";
import LoadingSection from "../../../helperComponents/LoadingSection";
import { useTranslation } from "react-i18next";
import goTo from "../../../../controllers/goTo";

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding: 40px 0;
  background-color: #000;
  color: #fff;
  width: 100%;
  font-family: Arial, sans-serif;

  @media (max-width: 900px) {
    flex-direction: column-reverse;
    justify-content: flex-start;
    gap: 90px;
  }
`;

const InfoSection = styled.div`
  max-width: 600px;
  display: flex;
  flex-direction: column;
  gap: 50px;

  @media (max-width: 900px) {
    width: 80vw;
  }
`;

const Title = styled.h1`
  font-size: 36px;
  color: #c9d400;
  margin: 0;
`;

const CoachAndDuration = styled.div`
  display: flex;
  align-items: center;

  @media (max-width: 900px) {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }
`;

const IconText = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-right: 30px;
  color: var(--accentColor);
  font-size: 18px;

  @media (max-width: 900px) {
    margin: 0;
  }
`;

const Icon = styled.span`
  display: flex;
  justify-content: center;
  align-items: center;
  /* display: inline-block; */
  margin-right: 10px;
`;

const Subtitle = styled.div`
  font-size: 24px;
  color: #c9d400;
  font-weight: 900;
  /* margin-bottom: 10px; */
`;

const Text = styled.p`
  font-size: 16px;
  line-height: 1.5;
  margin: 0;
`;

const List = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
`;

const ListItem = styled.li`
  font-size: 16px;
  margin-bottom: 8px;
  text-transform: capitalize;

  &::before {
    content: "•";
    color: #c9d400;
    margin-right: 8px;
  }
`;

const RightSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 50px;
  align-items: center;

  @media (max-width: 900px) {
    gap: 20px;
  }
`;

const Image = styled.img`
  width: 400px;
  border-radius: 0;

  @media (max-width: 900px) {
    width: 90vw;
  }
`;

const SeatsLeft = styled.div`
  font-size: 18px;
  padding: 20px;
  color: #c9d400;
`;

const ImageSection = styled.div`
  display: flex;
  flex-direction: column;
  background: var(--translucent);
  border: var(--highlightBorder);
  border-radius: 10px;
  overflow: hidden;
`;

const Section = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
`;

function EmployeeDetails({ item }) {
  const { t } = useTranslation();

  let bookButton = (
    <PrimaryButton
      onClick={goTo(`/upcoming-activities/?employeeID=${item._id}`)}
      style={{ width: "100%", borderRadius: "10px" }}
    >
      Réserver
    </PrimaryButton>
  );

  let theButton = bookButton;

  return (
    <Container>
      <InfoSection>
        <Section>
          <Title>{item.name}</Title>
          <CoachAndDuration>
            <IconText>
              <Icon>
                <FaPersonMilitaryPointing />
              </Icon>
              {t("adminPageEmployeeDesignation")}: {item.designation}
            </IconText>
          </CoachAndDuration>
        </Section>

        <Section>
          <Subtitle> {t("adminPageEmployeeDescription")}</Subtitle>
          <Text>{item.description}</Text>
        </Section>
      </InfoSection>

      <RightSection>
        <ImageSection>
          <Image src={getImageURL(item.image)} />
        </ImageSection>

        {theButton}
      </RightSection>
    </Container>
  );
}

export default EmployeeDetails;
