import styled from "styled-components";
import WhyChooseUs from "./WhyChooseUs.js";
import CompanyStat from "./CompanyStat.js";
import Testimonials from "./Testimonials.js";
import Footer from "./Footer.js";
import AboutUs from "./AboutUs.js";
import FeatureRow from "./FeatureRow.js";
import LoggedOutMembershipSection from "./LoggedOutMembershipSection.js";
import LoggedOutHeader from "./LoggedOutHeader.js";
import PrimaryButton from "../../../helperComponents/PrimaryButton.js";
import { useContext, useEffect, useRef, useState } from "react";
import ContactUs from "./ContactUs.js";
import Context from "../../../../Context.js";
import goTo from "../../../../controllers/goTo.js";
import { useTranslation } from "react-i18next";

const ParentContainer = styled.div`
  display: flex;
  width: 100vw;
  height: 100dvh;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
  overflow-y: scroll;
`;

const Main = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
`;

const Tagline = styled.div`
  font-weight: 900;
  font-size: 65px;
  font-family: "Futur", sans-serif;
  color: var(--accentColor);

  @media (max-width: 900px) {
    font-size: 35px;
    text-align: center;
    width: 100%;
  }
`;

const MainButtons = styled.div`
  display: flex;
  flex-direction: row;

  @media (max-width: 900px) {
    justify-content: center;
    width: 100%;
    flex-direction: column;
    align-items: center;
    gap: 10px;
  }
`;

const Medium = styled.div`
  font-size: 21px;
  font-weight: 600;
  opacity: 0.5;
  line-height: 30px;
  font-family: "Montserrat", sans-serif;

  @media (max-width: 900px) {
    width: 100%;
    font-size: 18px;
    line-height: 28px;
    text-align: center;
  }
`;

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 19px;
  align-items: flex-start;
  margin-top: 10px;
`;

const HeroSection = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 90vw;
  height: 60vh;
  gap: 100px;

  @media (max-width: 900px) {
    height: auto;
    gap: 20px;

    flex-direction: column-reverse;
  }
`;

const AnimatedHeroImage = styled.img`
  width: 900px;
  margin-right: -100px;
  margin-top: 50px;
  border-radius: 15px;

  @media (max-width: 900px) {
    width: 90vw;
    height: auto;
    margin: 0;
    margin-top: 50px;
  }
`;

export default function LoggedOutHomePage() {
  const { isMobile } = useContext(Context);

  const [refreshRef, setRefreshRef] = useState(null);

  const { t } = useTranslation();

  //without this line we will not be able to refresh once the page is changed
  //the above line prevents a buh in which when we go to the login page and backend to the homepage
  //the ref disappears

  useEffect(() => {
    setRefreshRef(true);
  }, []);

  const containerRef = useRef();

  console.log("containerRef.current", containerRef.current);

  return (
    <ParentContainer id="scrollableParent" ref={containerRef}>
      <LoggedOutHeader />
      <Main>
        <HeroSection>
          <TextContainer>
            <Tagline> {t("loggedOutHeroTitle")} </Tagline>

            <Medium>{t("loggedOutHeroDesc")} </Medium>

            <br />
            <MainButtons>
              <PrimaryButton
                onClick={goTo("/signup")}
                style={{
                  width: "300px",
                  borderRadius: isMobile ? "10px" : "100px",
                }}
                textStyle={{ fontSize: "18px" }}
              >
                {t("becomeAMember")}
              </PrimaryButton>

              {isMobile ? (
                <PrimaryButton
                  onClick={goTo("/auth")}
                  style={{ width: "300px", borderRadius: "10px" }}
                  textStyle={{ fontSize: "18px" }}
                >
                  {t("alreadyAMember")}
                </PrimaryButton>
              ) : null}
            </MainButtons>
          </TextContainer>
          <AnimatedHeroImage src="/hero/reception.jpg" />
        </HeroSection>
        <FeatureRow />

        {containerRef.current ? (
          <>
            <WhyChooseUs containerRef={containerRef} />
            <CompanyStat containerRef={containerRef} />
            <Testimonials containerRef={containerRef} />
            <LoggedOutMembershipSection containerRef={containerRef} />
            <AboutUs containerRef={containerRef} />
            <ContactUs containerRef={containerRef} />
            <Footer containerRef={containerRef} />
          </>
        ) : null}
      </Main>
    </ParentContainer>
  );
}
