import styled from "styled-components";

import LogoSVG from "./LogoSVG.js";
import Link from "../helperComponents/Link.js";
import goTo from "../../controllers/goTo.js";

const BrandContainerMain = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  cursor: pointer;
  align-items: center;
  gap: 10px;
`;

const H1 = styled.h1`
  font-weight: 800;
  margin: 0;
  margin-top: -3px;
  font-size: 40px;
  width: auto;
  font-family: "Raleway";
  /* width: 100%; */
`;

const LogoImg = styled.img`
  height: auto;
  width: 200px;

  @media (max-width: 900px) {
    width: 150px;
  }
`;

export default function BrandContainer() {
  window.goTo = goTo;
  return (
    <Link href="/">
      <BrandContainerMain>
        <LogoImg src={"/full-logo.jpg"} />
        {/* <H1>Wellness</H1> */}
      </BrandContainerMain>
    </Link>
  );
}
