import { styled } from "styled-components";
import { useContext, useEffect, useRef, useState } from "react";

import selectFile from "../../controllers/selectFile.js";
import compressAndUploadFile from "../../controllers/compressAndUploadFile.js";
import { serverLine } from "../../controllers/serverLine.js";

import Context from "../../Context.js";

import { AiOutlineCheck, AiOutlineUpload } from "react-icons/ai/index.js";

import ProfilePicture from "../helperComponents/ProfilePicture.js";
import ShowSelectedImage from "../helperComponents/ShowSelectedImage.js";

import MaterialInput from "../helperComponents/MaterialInput.js";
import CustomButton from "../helperComponents/CustomButton.js";

import LoadingSection from "../helperComponents/LoadingSection.js";

import DropDownLocationInput from "../helperComponents/DropDownLocationInput.js";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 0;
  width: 100%;

  gap: 10px;
  align-items: center;
`;

const Inputs = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  width: 100%;
  gap: 30px;
  justify-content: center;

  @media (max-width: 900px) {
    width: 90vw;
  }
`;

const Button = styled.div`
  width: 100%;
  font-size: 18px;
  border: 1px solid var(--translucent);
  background: var(--translucent);
  border-radius: 15px;
  color: var(--color);
  text-align: center;
  cursor: pointer;
  padding: 20px 15px;
`;

const Textarea = styled.textarea`
  font-size: 18px;
  border: none;
  border-bottom: 1px solid var(--translucentHard);
  background: transparent;
  border-radius: 0;
  color: var(--color);
  /* text-align: center; */
  width: 100%;
  font-family: "Inter";
  resize: none;

  padding: 15px 0;

  &::placeholder {
    color: var(--translucentHard);
  }
`;

const ImageUploadSection = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  gap: 25px;
  /* width: 100%; */
  justify-content: center;
  align-items: center;
`;

const ImageUploadButton = styled(Button)`
  /* width: 300px; */
  position: absolute;
  right: 2px;
  bottom: 5px;
  width: 50px;
  background: var(--glassGradient);
  backdrop-filter: blur(20px);
  box-shadow: var(--hardShadow);
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100px;
  height: 50px;

  transition: all 0.2s ease-in-out;
  border: 1px solid var(--glassBorder);

  &:hover {
    transform: scale(1.2);
    box-shadow: var(--lightShadow);
  }
`;

export default function BasicInfoEditor({ callback }) {
  const { loggedInUser, setLoggedInUser, popupAlert } = useContext(Context);
  const [config, setConfig] = useState({});

  const [selectedImages, setSelectedImages] = useState(null);

  const [loading, setLoading] = useState(false);

  const inputRef = useRef(null);

  useEffect(() => {
    if (loggedInUser) {
      let newState = { ...config };
      newState = { ...loggedInUser };
      setConfig(newState);
    }
  }, [loggedInUser]);

  if (loading || !loggedInUser) return <LoadingSection />;

  let profilePicObj = selectedImages ? (
    <ShowSelectedImage
      height={"200px"}
      width={"200px"}
      fileObject={selectedImages[0]}
    />
  ) : (
    <ProfilePicture user={loggedInUser} height="200px" width="200px" />
  );

  return (
    <Container>
      <Inputs>
        <ImageUploadSection>
          {profilePicObj}
          <ImageUploadButton onClick={chooseImage}>
            <AiOutlineUpload />
          </ImageUploadButton>
        </ImageUploadSection>

        <MaterialInput
          value={config.name}
          onChange={makeChange("name")}
          label={"Full Name"}
        />

        <MaterialInput
          value={config.username}
          onChange={makeChange("username")}
          label={"Username"}
        />
        <MaterialInput
          value={config.tagline}
          onChange={makeChange("tagline")}
          label={"Tagline. Describe yourself in one sentence."}
        />
        <MaterialInput
          value={config.bio}
          multiline={true}
          rows={3}
          onChange={makeChange("bio")}
          label={"Description. Describe yourself in one paragraph."}
        />

        <DropDownLocationInput
          onChange={makeChange("country", true)}
          type="COUNTRY"
          placeholder={"Select Country"}
          value={config.country}
        />
      </Inputs>
      <CustomButton
        style={{ width: "100%", borderRadius: "10px" }}
        icon={<AiOutlineCheck />}
        onClick={save}
        variant="filled"
      >
        Save
      </CustomButton>
      <br /> <br />
    </Container>
  );

  function makeChange(fieldName, isString) {
    return (e) => {
      if (isString) {
        let newItem = { ...config };
        newItem[fieldName] = e;
        setConfig(newItem);
        return true;
      }

      let newItem = { ...config };
      newItem[fieldName] = e.target.value;
      setConfig(newItem);
    };
  }

  function isValidLink(url) {
    var re =
      /^((https?|ftp|smtp):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/;
    return re.test(url);
  }

  function chooseImage() {
    selectFile({ onlyImage: true }).then(setSelectedImages);
  }

  async function uploadImage() {
    if (!selectedImages) return null;
    if (!selectedImages.length) return null;
    setLoading(true);
    console.log("Uploading Image");
    let newFile = await compressAndUploadFile(null, selectedImages[0]);
    setLoading(false);
    return newFile;
  }

  function isAlphaNumeric(str) {
    return /^[a-zA-Z0-9]+$/.test(str);
  }

  async function save() {
    if (!config.tagline) return popupAlert("Tagline is missing");
    if (!config.country) return popupAlert("Country is missing");

    if (config.username) {
      if (!isAlphaNumeric(config.username)) {
        return popupAlert(
          "The username can only contain alphabets and numbers"
        );
      }
    }

    try {
      let fileData = await uploadImage();

      let toSend = {
        changes: {},
      };

      toSend.changes.onboarding = "basicInfoAdded";

      toSend.changes.country = config.country;
      toSend.changes.name = config.name;
      toSend.changes.username = config.username;
      toSend.changes.tagline = config.tagline;
      toSend.changes.bio = config.bio;
      toSend.changes.socialLinks = config.socialLinks;
      toSend.changes.country = config.country;
      toSend.changes.state = config.state;
      toSend.changes.city = config.city;
      toSend.changes.skillTags = config.skillTags;

      let newConfig = { ...config };

      if (fileData) {
        if (fileData.fileName) {
          toSend.changes.profileImage = {
            type: "S3_UPLOAD",
            data: fileData.fileName,
          };

          newConfig.profileImage = toSend.changes.profileImage;
        }
      }

      setLoading(true);

      await serverLine.patch("/profile", toSend);

      let newLoggedInUser = { ...loggedInUser, ...newConfig };

      if (newLoggedInUser.onboarding) {
        newLoggedInUser.onboarding.basicInfoAdded = true;
      } else {
        newLoggedInUser.onboarding = { basicInfoAdded: true };
      }

      console.log("newLoggedInUser", newLoggedInUser);
      setLoggedInUser(newLoggedInUser);

      setLoading(false);

      popupAlert("Saved!");
    } catch (e) {
      setLoading(false);
      popupAlert(e.message);
    }
  }
}
